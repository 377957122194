<template>
  <div class="container">
    <pre style="display: none">{{ pagination | pretty_json }}}</pre>
    <h1>Lista de Estruturas Organizacionais</h1>
    <div class="card">
      <div class="card-body">
        <b-table
          striped
          hover
          :items="organizational_structures"
          :fields="fields"
        >
          <template #cell(actions)="row">
            <b-link
              :to="{
                name: 'organizational_structures_show',
                params: { id: row.item.id }
              }"
              class="mr-2"
            >
              <b-icon icon="info-circle-fill" font-scale="1.5" variant="info" />
            </b-link>
            <b-link
              :to="{
                name: 'organizational_structures_edit',
                params: { id: row.item.id }
              }"
              class="mr-2"
            >
              <b-iconstack font-scale="1.5" variant="warning">
                <b-icon stacked icon="circle-fill"></b-icon>
                <b-icon
                  stacked
                  icon="pencil"
                  variant="dark"
                  scale=".6"
                ></b-icon>
              </b-iconstack>
            </b-link>

            <b-icon
              @click="delete_organizational_structure(row.item)"
              icon="x-circle"
              variant="danger"
              font-scale="1.5"
            ></b-icon>
          </template>
        </b-table>
      </div>
    </div>
    <div class="overflow-auto mt-4">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
      ></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapState("organizational_structures", [
      "organizational_structures",
      "pagination"
    ]),
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "name", label: "Nome" },
        { key: "cpf", label: "CPF" },
        { key: "rg", label: "RG" },
        { key: "address.county_id", label: "Município" },
        { key: "actions", label: "#" }
      ];
    }
  },
  methods: {
    ...mapActions("organizational_structures", ["ActionGetAll"]),
    delete_organizational_structure(organizational_structure) {
      return organizational_structure;
    },

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    }
  },
  async created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }
    await this.ActionGetAll({ page: this.page });
  }
};
</script>

<style></style>
