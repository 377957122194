var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('pre',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm._f("pretty_json")(_vm.pagination))+"}")]),_c('h1',[_vm._v("Lista de Estruturas Organizacionais")]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.organizational_structures,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('b-link',{staticClass:"mr-2",attrs:{"to":{
              name: 'organizational_structures_show',
              params: { id: row.item.id }
            }}},[_c('b-icon',{attrs:{"icon":"info-circle-fill","font-scale":"1.5","variant":"info"}})],1),_c('b-link',{staticClass:"mr-2",attrs:{"to":{
              name: 'organizational_structures_edit',
              params: { id: row.item.id }
            }}},[_c('b-iconstack',{attrs:{"font-scale":"1.5","variant":"warning"}},[_c('b-icon',{attrs:{"stacked":"","icon":"circle-fill"}}),_c('b-icon',{attrs:{"stacked":"","icon":"pencil","variant":"dark","scale":".6"}})],1)],1),_c('b-icon',{attrs:{"icon":"x-circle","variant":"danger","font-scale":"1.5"},on:{"click":function($event){return _vm.delete_organizational_structure(row.item)}}})]}}])})],1)]),_c('div',{staticClass:"overflow-auto mt-4"},[_c('b-pagination-nav',{attrs:{"link-gen":_vm.linkGen,"number-of-pages":_vm.pagination.last_page,"use-router":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }